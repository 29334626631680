<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="dense-wrapper">
          <v-col cols="12" md="12" class="pt-3 d-flex align-center">
            <v-text-field type="text" filled label="Код" v-model="code"
                          hide-details
                          disabled
                          color="grey"/>
            <v-checkbox type="text" filled label="Це послуга" v-model="is_service"
                        hide-details
                        class="mt-2 ml-2"
                        color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <ACC_Nomenclature
                :value="parent_id"
                :except_value="itemId"
                @autocompleteChange="parentChange"
                color="grey"
                :select_type="'groups'"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва коротка" v-model="short_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="short_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва повна" v-model="full_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="full_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6" v-if="!is_group">
            <AC_UnitType type="text" filled label="Одиниця виміру" v-model="unit_type"
                         hide-details
                         required :rules="[v => !!v || 'Це поле є обов’язковим']"
                         :class="unit_type ? '' : 'req-star'"
                         @autocompleteChange="unitTypeChange"
                         color="grey"/>
          </v-col>
          <v-col cols="6" md="6" v-if="!is_group" class="d-flex">
            <v-select type="text" filled label="Ставка ПДВ" v-model="tax_type"
                      hide-details
                      :items="tax_select"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="tax_select ? '' : 'req-star'"
                      color="grey"/>
            <v-checkbox type="text" filled label="ПДВ (в т.ч)" v-model="tax_in_sum"
                        hide-details
                        style="flex: 0 0 120px"
                        class="mt-2 ml-2"
                        color="grey"/>
          </v-col>
          <v-col cols="6" v-if="!is_service && !is_group">
            <v-text-field type="text" filled label="Код УКТ ЗЕД" v-model="code_uktz"
                          hide-details
                          color="grey"/>
          </v-col>
          <v-col cols="6" v-if="is_service && !is_group">
            <v-text-field type="text" filled label="Код ДКПП" v-model="code_dkpp"
                          hide-details
                          color="grey"/>
          </v-col>
          <v-col cols="6" v-if="!is_group">
            <v-checkbox type="text" filled label="Це імпортований товар" v-model="its_import_good"
                        hide-details
                        class="mt-2 ml-2"
                        color="grey"/>
          </v-col>
          <v-col cols="6" v-if="!is_group">
            <ACC_ChartOfAccount
                :value="chart_of_account_id"
                @autocompleteChange="chartOfAccountChange"
                color="grey"
                label="Рахунок витрат (для юр. обліку)"
            />
          </v-col>
          <v-col cols="6" v-if="account_detail_1_type && !is_group">
            <ACC_Subconto
                v-model="account_detail_1_value"
                :computed-action="subconto_type_select_setting[account_detail_1_type].computed_action"
                :computed-getter="subconto_type_select_setting[account_detail_1_type].computed_getter"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                :show-select-on-focus="false"
                label="Субконто 1 (для юр.обліку)"
                :required="true"
                :clearable="true"
                :select-button-object="{
                                name: subconto_type_select_setting[account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_1_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                @autocompleteChange="detail1Change"
            />
          </v-col>
          <v-col cols="6" v-if="account_detail_2_type && !is_group">
            <ACC_Subconto
                v-model="account_detail_2_value"
                :computed-action="subconto_type_select_setting[account_detail_2_type].computed_action"
                :computed-getter="subconto_type_select_setting[account_detail_2_type].computed_getter"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                :show-select-on-focus="false"
                label="Субконто 2 (для юр.обліку)"
                :required="true"
                :clearable="true"
                :select-button-object="{
                                name: subconto_type_select_setting[account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_2_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                @autocompleteChange="detail2Change"
            />
          </v-col>
          <v-col cols="6" v-if="account_detail_3_type && !is_group">
            <ACC_Subconto
                v-model="account_detail_3_value"
                :computed-action="subconto_type_select_setting[account_detail_3_type].computed_action"
                :computed-getter="subconto_type_select_setting[account_detail_3_type].computed_getter"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                :show-select-on-focus="false"
                label="Субконто 3 (для юр.обліку)"
                :required="true"
                :clearable="true"
                :select-button-object="{
                                name: subconto_type_select_setting[account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_3_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                @autocompleteChange="detail3Change"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_NOMENCLATURE_LIST_GROUP,
  CREATE_NOMENCLATURE_LIST_ELEMENTS,
  UPDATE_NOMENCLATURE_LIST_GROUP,
  UPDATE_NOMENCLATURE_LIST_ELEMENTS,
  REMOVE_NOMENCLATURE_LIST_GROUP,
  REMOVE_NOMENCLATURE_LIST_ELEMENTS,
  SELECT_TAXES_LIST_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapActions, mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {SELECT_UNIT_TYPE} from "@/store/actions/flat_indicators";
import {subconto_type_select_setting} from "@/utils/accounting";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'nomenclature_element_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Nomenclature_Element",
  mixins: [ModalComponentMixin],
  components: {
    ACC_Subconto: () => import("@/components/accounting/autocomplite/modal/ACC_Subconto"),
    ACC_Nomenclature: () => import("@/components/accounting/autocomplite/modal/ACC_Nomenclature"),
    AC_UnitType: () => import("@/components/autocomplite/AC_UnitType"),
    ACC_ChartOfAccount: () => import("@/components/accounting/autocomplite/modal/ACC_Chart_of_account")
  },
  data() {
    return {
      subconto_type_select_setting,
      short_name: this.item.short_name,
      full_name: this.item.full_name,
      code: this.item.code || '',
      is_service: this.item.is_service || false,
      parent_id: this.item.parent_id || null,
      unit_type: this.item.unit_type || null,
      tax_type: this.item.tax_type || null,
      code_uktz: this.item.code_uktz || null,
      code_dkpp: this.item.code_dkpp || null,
      its_import_good: this.item.its_import_good || false,
      chart_of_account_id: this.item.chart_of_account_id || null,
      account_detail_1_value: this.item.account_detail_1_value || null,
      account_detail_2_value: this.item.account_detail_2_value || null,
      account_detail_3_value: this.item.account_detail_3_value || null,
      account_detail_1_type: this.item.account_detail_1_type || null,
      account_detail_2_type: this.item.account_detail_1_type || null,
      account_detail_3_type: this.item.account_detail_1_type || null,
      tax_in_sum: this.item.tax_in_sum || false,
      is_group: this.item.is_group,
      charts: [],
    }
  },
  methods: {
    ...mapActions({
      fetchUnitSelect: SELECT_UNIT_TYPE,
      fetchTaxSelect: SELECT_TAXES_LIST_ELEMENTS
    }),
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data
          })
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },
    chartOfAccountChange(payload) {
      this.chart_of_account_id = (payload || {})?.value || null
      this.$nextTick(() => {
        let details = {}
        if (payload) {
          if ('text' in payload && 'value' in payload) {
            details = this.get_account_details(payload.value)
          } else {
            details = this.get_account_details(payload.id)
          }
          this.account_detail_1_type = details.account_detail_1.type
          this.account_detail_1_value = null
          this.account_detail_2_type = details.account_detail_2.type
          this.account_detail_2_value = null
          this.account_detail_3_type = details.account_detail_3.type
          this.account_detail_3_value = null
        } else {
          this.account_detail_1_type = null
          this.account_detail_1_value = null
          this.account_detail_2_type = null
          this.account_detail_2_value = null
          this.account_detail_3_type = null
          this.account_detail_3_value = null
        }

      })
    },
    detail1Change(payload) {
      this.account_detail_1_value = (payload || {})?.value || null
    },
    detail2Change(payload) {
      this.account_detail_2_value = (payload || {})?.value || null
    },
    detail3Change(payload) {
      this.account_detail_3_value = (payload || {})?.value || null
    },
    parentChange(payload) {
      this.parent_id = (payload || {})?.value || null
    },
    unitTypeChange(payload) {
      this.unit_type = (payload || {})?.value || null
    },
    closeModal(operation=null) {
      this.$emit('closeModal', operation)
      this.itemId = this.item.id
      this.short_name = this.item.short_name
      this.full_name = this.item.full_name
      this.code = this.item.code
      this.is_service = this.item.is_service || false
      this.parent_id = this.item.parent_id || null
      this.unit_type = this.item.unit_type || null
      this.tax_type = this.item.tax_type || null
      this.is_group = this.item.is_group
      this.chart_of_account_id = this.item.chart_of_account_id || null
      this.account_detail_1_type = this.item.account_detail_1_type || null
      this.account_detail_2_type = this.item.account_detail_2_type || null
      this.account_detail_3_type = this.item.account_detail_3_type || null
      this.account_detail_1_value = this.item.account_detail_1_value || null
      this.account_detail_2_value = this.item.account_detail_2_value || null
      this.account_detail_3_value = this.item.account_detail_3_value || null
      this.code_uktz = this.item.code_uktz || null
      this.code_dkpp = this.item.code_dkpp || null
      this.its_import_good = this.item.its_import_good || false
      this.tax_in_sum = this.item.tax_in_sum || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.$emit('closeModal')
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення складу № ${this.short_name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        const error_text = this.is_group
            ? 'Коротка назва, повна назва - мають бути заповнені'
            : 'Коротка назва, повна назва, ставка ПДВ, одиниця виміру - мають бути заповнені'
        this.$store.commit(ALERT_SHOW, {
          text: error_text,
          color: 'error lighten-1'
        })
        return
      }

      if (this.chart_of_account_id) {
        if (this.account_detail_1_type && !this.account_detail_1_value) {
          this.$store.commit(ALERT_SHOW, {
            text: 'Субконто 1 - не заповнене',
            color: 'error lighten-1'
          })
          return
        }
      }

      if (this.chart_of_account_id) {
        if (this.account_detail_2_type && !this.account_detail_2_value) {
          this.$store.commit(ALERT_SHOW, {
            text: 'Субконто 2 - не заповнене',
            color: 'error lighten-1'
          })
          return
        }
      }

      if (this.chart_of_account_id) {
        if (this.account_detail_3_type && !this.account_detail_3_value) {
          this.$store.commit(ALERT_SHOW, {
            text: 'Субконто 3 - не заповнене',
            color: 'error lighten-1'
          })
          return
        }
      }

      const payload = {
        short_name: this.short_name,
        full_name: this.full_name,
        code: this.code,
        parent_id: this.parent_id || null,
        unit_type: this.unit_type || null,
        tax_type: this.tax_type || null,
        is_group: this.is_group,
        is_service: this.is_service || false,
        chart_of_account_id: this.chart_of_account_id || null,
        account_detail_1_type: this.account_detail_1_type || null,
        account_detail_2_type: this.account_detail_2_type || null,
        account_detail_3_type: this.account_detail_3_type || null,
        account_detail_1_value: this.account_detail_1_value || null,
        account_detail_2_value: this.account_detail_2_value || null,
        account_detail_3_value: this.account_detail_3_value || null,
        tax_in_sum: this.tax_in_sum || false,
        code_uktz: this.code_uktz || null,
        code_dkpp: this.code_dkpp || null,
        its_import_good: this.its_import_good || false
      }

      if (this.isNew) {
        this.$store.dispatch(this.is_group ? CREATE_NOMENCLATURE_LIST_GROUP : CREATE_NOMENCLATURE_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal({ fetch: true, group: this.is_group, emit: true })
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(this.is_group ? UPDATE_NOMENCLATURE_LIST_GROUP : UPDATE_NOMENCLATURE_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal({ fetch: true, group: this.is_group  })
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.short_name = payload.short_name
              this.full_name = payload.full_name
              this.code = payload.code
              this.parent_id = payload.parent_id || null
              this.unit_type = payload.unit_type || null
              this.tax_type = payload.tax_type || null
              this.is_group = payload.is_group
              this.chart_of_account_id = payload.chart_of_account_id
              this.account_detail_1_type = payload.account_detail_1_type
              this.account_detail_2_type = payload.account_detail_2_type
              this.account_detail_3_type = payload.account_detail_3_type
              this.account_detail_1_value = payload.account_detail_1_value
              this.account_detail_2_value = payload.account_detail_2_value
              this.account_detail_3_value = payload.account_detail_3_value
              this.tax_in_sum = payload.tax_in_sum || false
              this.is_service = payload.is_service || false
              this.code_uktz = payload.code_uktz || null
              this.code_dkpp = payload.code_dkpp || null
              this.its_import_good = payload.its_import_good || false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(this.is_group ? REMOVE_NOMENCLATURE_LIST_GROUP : REMOVE_NOMENCLATURE_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal({ fetch: true, group: this.is_group, emit: true })
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      unit_select: 'getUnitTypesSelect',
      tax_select: 'get_taxes_list_pdv_select'
    }),
  },
  created() {
    this.getChartsOfAccounts()
    this.fetchUnitSelect()
    this.fetchTaxSelect()
  }
}
</script>
